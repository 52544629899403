<template>
  <div class="sb-additional-service-page">
    <div :class="{ 'inner-container fad': !isMd }">
      <div>
        <div v-for="(parent, idx) in getServicesParentList" :key="parent" class="sb-additional-service-page-container">
          <SbTitle v-if="titles" v-bind="titles[idx]" />
          <SbSubtitle v-if="subtitles" v-bind="subtitles[idx]" />
          <div v-for="child in getServicesChildList(parent)" :key="child">
            <div
              v-if="getStoryBySlug(child) && getStoryBySlug(child).content"
              :id="getStoryBySlug(child).slug"
              class="sb-additional-service-page-container-wrapper"
            >
              <div
                v-if="getStoryBySlug(child).content.main_img?.filename"
                class="sb-additional-service-page-container-wrapper-left"
              >
                <div class="sb-additional-service-page-container-wrapper-left-img">
                  <ImageLazy :src="getStoryBySlug(child).content.main_img.filename" />
                </div>
              </div>

              <div class="sb-additional-service-page-container-wrapper-right">
                <div class="sb-additional-service-page-container-wrapper-right-tagline">
                  <span>{{ getStoryBySlug(child).content.tagline }}</span>
                </div>
                <div
                  v-if="getStoryBySlug(child).content?.icon?.filename"
                  class="sb-additional-service-page-container-wrapper-right-title"
                >
                  <ImageLazy
                    v-if="getStoryBySlug(child).content.icon"
                    :src="getStoryBySlug(child).content.icon.filename"
                    class-variant="sb-icon"
                  />
                  <span>{{ getStoryBySlug(child).content.title }}</span>
                </div>

                <div class="sb-additional-service-page-container-wrapper-right-text">
                  <span v-html="richText(getStoryBySlug(child).content.description)" />
                </div>

                <div class="sb-additional-service-page-container-wrapper-right-buttons u-d-f u-fw-wrap-md">
                  <SbCtaGroup :buttons="getStoryBySlug(child).content.buttons" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Mobile from '@/mixins/mobile-mixin'

import SbCtaGroup from '@/components/Storyblok/SbCtaGroup'
import SbTitle from '@/components/Storyblok/SbTitle'
import SbSubtitle from '@/components/Storyblok/SbSubtitle'

export default {
  name: 'SbAdditionalServicesPage',
  components: {
    SbCtaGroup,
    SbTitle,
    SbSubtitle,
  },
  mixins: [Mobile],
  props: {
    titles: { type: Array, default: () => [] },
    subtitles: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters('proArea', ['getServicesChildList', 'getServicesParentList', 'getStoryBySlug']),
    selectedServices() {
      const parents = this.getServicesParentList
      let children = []
      parents.forEach(x => {
        children = [...children, ...this.getServicesChildList(x)]
      })
      return children.map(x => this.getStoryBySlug(x))
    },
  },
  methods: {
    richText(text) {
      if (text) {
        return renderRichText(text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.sb-additional-service-page {
  padding: $spacing-xl;
  background-color: var(--white);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-container {
    div:nth-child(even) {
      .sb-additional-service-page-container-wrapper {
        flex-direction: row-reverse;
      }
    }
    &-wrapper {
      display: flex;
      gap: $spacing-md;
      margin: 0 auto;
      padding-bottom: $spacing-xxl;
      padding-top: $spacing-xxl;

      @include mq($mq-sm) {
        flex-direction: column !important;
        padding-bottom: $spacing-sm;
        padding-top: $spacing-sm;
      }

      &-left {
        flex: 1;
        &-img {
          width: 490px;
          height: 400px;
          border-radius: 10px;
          overflow: hidden;

          @include mq($mq-md) {
            width: 322px;
          }

          @include mq($mq-sm) {
            width: 100%;
            height: 275px;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-right {
        flex: 1;
        &-tagline {
          font-weight: 800;
          font-size: pxToRem(25px);
          color: var(--dark-blue);
          padding-bottom: $spacing-sm;
        }

        &-title {
          display: flex;
          align-items: center;
          gap: $spacing-sm;
          font-size: pxToRem(15px);
          color: var(--dark-blue);
          padding-bottom: $spacing-sm;
        }

        &-links {
          padding-bottom: $spacing-md;
          .sb-buttons-link-hover-img {
            width: 38px;
            height: 38px;
          }
        }

        &-text {
          font-weight: 400;
          font-size: pxToRem(15px);
          color: var(--dark-blue);
          padding-bottom: $spacing-md;
        }

        &-buttons {
          justify-content: space-around;
          padding-bottom: $spacing-md;

          &-button {
            position: relative;
            flex-grow: 1;

            &-icon {
              position: absolute;
              right: 10px;
              width: 28px;
              height: 28px;

              @include mq($mq-md) {
                display: none;
              }

              @include mq($mq-sm) {
                display: block;
              }
            }
          }
        }

        &-testimony {
          padding: $spacing-md;
          border-radius: 10px;
          background-color: var(--bg-grey);
          gap: $spacing-md;

          &-left {
            &-img {
              width: 80px;
              height: 80px;
              border-radius: 90px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &-right {
            &-name {
              font-weight: 800;
              font-size: pxToRem(15px);
              padding-bottom: $spacing-sm;

              &-role {
                color: var(--green);
              }
            }

            &-text {
              font-weight: 500;
              font-size: pxToRem(12px);
            }
          }
        }
      }
    }
    &-companies {
      align-items: center;
      display: flex;
      flex: 1;
      padding-top: $spacing-md;
    }
  }
  .grayscale {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
    &:hover {
      filter: grayscale(0);
    }
  }
}
</style>
